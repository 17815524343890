
.errorContainer {
  text-align: center;
  font-size: 180px;
  font-weight: 800;
  margin: 20px 15px;
}
.errorContainer > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #4b00f9;
}
.errorContainer > span:nth-of-type(1) {
  color: #1de5ff;
  animation: colordancing 6s infinite;
}
.errorContainer > span:nth-of-type(3) {
  color: #a127ff;
  animation: colordancing2 6s infinite;
}
.errorContainer > span:nth-of-type(2) {
  width: 120px;
  height: 100px;
  border-radius: 999px;
}
.errorContainer > span:nth-of-type(2):before,
.errorContainer > span:nth-of-type(2):after {
  border-radius: 0%;
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: inherit; height: inherit;
  border-radius: 999px;
  box-shadow: inset 30px 0 0 rgba(148, 0, 211, 0.4),
        inset 0 30px 0 rgba(239, 250, 180, 0.4),
        inset -30px 0 0 rgba(255, 196, 140, 0.4), 
        inset 0 -30px 0 rgba(75, 0, 130, 0.4);
  animation: shadowsdancing 6s infinite;
}
.errorContainer > span:nth-of-type(2):before {
  -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
      transform: rotate(45deg);
}

.screenReaderText {
    position: absolute;
    top: -9999em;
    left: -9999em;
}

@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(148, 0, 211, 0.4),
        inset 0 30px 0 rgba(239, 250, 180, 0.4),
        inset -30px 0 0 rgba(255, 196, 140, 0.4), 
        inset 0 -30px 0 rgba(75, 0, 130, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(75, 0, 130, 0.4),
        inset 0 30px 0 rgba(148, 0, 211, 0.4),
        inset -30px 0 0 rgba(239, 250, 180, 0.4), 
        inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
     box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 30px 0 rgba(75, 0, 130, 0.4),
        inset -30px 0 0 rgba(148, 0, 211, 0.4), 
        inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
   box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
        inset 0 30px 0 rgba(255, 196, 140, 0.4),
        inset -30px 0 0 rgba(75, 0, 130, 0.4), 
        inset 0 -30px 0 rgba(148, 0, 211, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(148, 0, 211, 0.4),
        inset 0 30px 0 rgba(239, 250, 180, 0.4),
        inset -30px 0 0 rgba(255, 196, 140, 0.4), 
        inset 0 -30px 0 rgba(75, 0, 130, 0.4);
  }
}

@keyframes colordancing {
  0% {
    color: #00BFFF;
  }
  25% {
    color: #4B0082;
  }
  50% {
    color: #8B008B;
  }
  75% {
    color: #0000c3;
  }
  100% {
    color: #B0E0E6;
  }
}

@keyframes colordancing2 {
  0% {
    color: #B0E0E6;
  }
  25% {
    color: #0000c3;
  }
  50% {
    color: #00BFFF;
  }
  75% {
    color: #4B0082;
  }
  100% {
    color: #8B008B;
  }
}

/* demo stuff */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
body {
  background-color: lightcyan;
  margin-bottom: 50px;
}
html, button, input, select, textarea {
    color: darkblue;
}
h1 {
  text-align: center;
  margin: 30px 15px;
}
.zoomArea { 
  max-width: 600px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
.linkContainer {
  text-align: center;
}
a.moreLink {
  text-transform: uppercase;
  font-size: 13px;
    background-color: #92a4ad;
    padding: 10px 15px;
    border-radius: 0;
    color: #416475;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}

.link{
    color:darkblue;
    text-decoration: none;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 20px 0;

    &:hover{
        text-decoration: underline;
    }
}

.container {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
