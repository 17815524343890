.container {
  position: relative;
  width: 40px; 
  height: 40px; 
  cursor: pointer;
  vertical-align: middle;
  margin-left:auto;
  margin-right: auto;
  &hover{
    width: 30px;
    height: 30px;
  }
}

.zindex0 {
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zindex0 img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius:13px;
}
  
.transparent {
  opacity: 0.1;
}
  
.zindex1 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 55%; 
  height: 55%; 
  z-index: 1;
}
  
.center {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  width: 100%; 
  height: 100%; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-left:auto;
  margin-right:auto;
}
  
.wave {
  width: 3px;
  height: 15px; 
  background: linear-gradient(45deg, rgb(5, 241, 241), #ca7af8);
  margin-right: 2px;
  animation: wave 0.8s linear infinite;
  border-radius: 20px;
}
  
.wave:nth-child(2) {
  animation-delay: 0.2s;
}
  
.wave:nth-child(3) {
  animation-delay: 0.4s;
}
  
.wave:nth-child(4) {
  animation-delay: 0.6s;
}
  
.wave:nth-child(5) {
  animation-delay: 0.8s;
}
  
@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
