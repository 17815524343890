.container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 90%;
    margin: 20px auto;   
    height: 30%; 
}

.topTicket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:0px;
    max-height:30%;
}

.bottomTicket {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topLeftTicket{
    display:flex;
    flex-direction:row;
    vertical-align:baseline;
    margin-top:0px;
}

.name{
    font-weight:bolder;
    margin-left: 20px;
    font-size:30px;
}
.vendorData{
    font-weight: 100;
    color:lightgray;
    font-size:14pt;
    margin-top:0px;

}

.numeroDemande{
    font-size:20px;
    font-weight: 100;
    color:lightgrey;

    vertical-align: middle;

    img{
        margin-right:20px;
        width:48px;
        height:40px;
        object-fit: contain;
        vertical-align: bottom;

    }
}

.iconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  
.folderImg{
    /*width : 39px;
    height : 32px;
    object-fit: cover;
    margin-right:15px;

    &:hover{
        cursor: pointer;
    }*/
    width:40px;
    height:40px;
    margin-right:30px;
    display: flex;
    flex-direction: column;
    cursor: pointer;    
    align-items:center;
}

.xlsImg{
    /*width : 36px;
    height : 28px;
    object-fit: cover;
    margin-right:15px;

    &:hover{
        cursor: pointer;
    }*/

    width : 40px;
    height : 40px;
    margin-right:25px;
    display: flex;
    flex-direction: column;
    align-items:center;
    cursor: pointer;
}

/*.eyeIcon{
    
    width : 39px;
    height : 30px;
    margin-right:15px;
    object-fit: cover;

    &:hover{
        cursor: pointer;
    }
}*/

.status{
    width: 170px;
    padding-left: 15px;
    padding-right:15px;
}


.eyeIcon {
    width:40px;
    height:40px;
    margin-right:25px;
    display: flex;
    flex-direction: column;
    cursor: pointer;    
    justify-content:center ;
}
.eyeIcon img{
    width: 70%;
    height: 70%;

}

.eyeIcon img:hover {
    width: 85%;
    height: 85%;
    object-fit: contain;
  }
