.container {
    background-color: rgb(215, 236, 242);
    width: 100%;
    overflow: scroll;
    height: 100vh;
    display: flex;
    justify-content: center;
    /*padding-left: 20px;*/
    z-index: 1;

}

.form{
    width: 85%;
    align-items: center;
    margin : auto;
}


.row {
    display: flex;
    flex-direction: row;
}

.input{
    padding : 5px;
    margin : 20px;
    width: 93%;
    background-color: #f1f1f1;

}

.containerChamps{
    display:flex;
    flex-direction: row;
}

.champs{    
    width: 86%;
    flex-direction: column;
}

.containerTextarea{
    width:100%;
    display: flex;
    justify-content:flex-start;
}

.textarea {
    width:100%;
    height: 100px;
    margin: 20px;
    padding : 10px;
    background-color: #f1f1f1;
    flex-direction: row;
    height:75%;
    max-height: max-content;
    width:100%;
}

.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height:100%;
    background-color: rgb(79, 79, 79, 0.2);
    /*box-shadow: -2px 0px 0px 0px black;
    border-radius: 10px;*/
    vertical-align:middle;


    z-index: 2;
    position: absolute;

}

.encartPopup{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin : auto;
    width: 20%;
    height:20%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

}