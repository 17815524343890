.container {
    display: flex;
    flex-direction: column;
    height: 90vh;
    width: 100%;
    background-color: lightcyan;
    color: white;

}

.squareContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 3%;
    margin-bottom:0px; 
    padding-bottom:0px; 
}

h1 {
    font-size: 2.5em;
    margin-bottom: 5%;
    align-self: center;
}

input {
    width: 100%;
    height: 25px;
    border-radius: 5px;
    border: none;
    background-color: white;
    color: black;
}

select {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: white;
    color: black;
}

textarea {
    width: 100%;
    max-width: 100% ;
    height: 100px;
    border-radius: 5px;
    border: none;
    background-color: white;
    color: black;
}

.square {
    display: flex;
    flex-direction: column;
    background-color: lightcyan;
    color: black;
    height: 85%;
    width: 45%;
    border-radius: 10px;
    margin-top: 10%;
    padding-left:20px;
    text-align:left;

}

.divElementLeft {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 3%;
}

.divElementRight {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.file {
    width: 100%;
    height: 145px;
    border-radius: 10px;
    background-color: rgb(79, 79, 79, 0.2);
    border: dashed 2px black;
    color: black;
}

.root{
    display:flex;
    flex-direction: column;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
}

.pageTitle{
    display:flex;
    flex-direction: row;
    height:50px;
    background-color: white;
    width:100%;
    max-width:100%;
    -webkit-box-shadow:0 0 6px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow:0 0 6px 3px rgba(0, 0, 0, 0.3);
    box-shadow:0 4px 6px 1px rgba(121, 121, 121, 0.1);
    z-index: 101;

    

    h2{

        text-align: center;
        color:#000080;
        font-family: helvetica;
    }
}


.dragAndDrop {
    position: relative;

    width: 90%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom:20px;
    align-items: center;
    text-align: left;



    .dropzone {
        position: relative;
        margin-top:10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 160px;
        background-color: white;
        border: 2px dashed white;
        border-radius: 10px;
    
        label {
            font-size: smaller;
        }

        input{
            background-color: white;
        }
    
        &:hover {
            border: 2px dashed cyan;
        }
    }
}


.dropzone input[type="file"] {
    top: 0;
    left: 0;
    width: 100%;
    max-width: fit-content;
    height: 100%;
    padding-right:10px;
    /*opacity: 0;*/
    cursor: pointer;
    margin-bottom: 0px;
    color:white;
}

.filesList {
    list-style-type: none;
    padding-left:10px;

}

.filesList li {
    display: flex;
    align-items: center;
    vertical-align: middle;
    font-size: smaller;
}

.fileName {
    margin-right: 10px;
    color:black;
}

.cancelFile{
        width:12px;
        height:12px;
        object-fit: cover;

        &:hover{
            width:15px;
            height:15px;
            object-fit: cover;        }
        
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.4);

    p{
        color:black;
    }
}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.closeIconImg {
    width: 20px;
    height: 20px;
}

.listeDoc{
    margin-top:0px;
}