.App {
  height: auto;
  background-color: white;

}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

img{
  aspect-ratio: 1 / 1; /* Remplacez 1 / 1 par le ratio de votre image si différent */
  object-fit: contain;
}
.App-header {
  background-color: #D5D5D5;
  min-height: 100vh;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  color: black;
}
/* 
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

::-webkit-scrollbar {
  width: 15px;
  height: 20px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #989797aa; /**4F4F4F*/
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(117, 116, 116, 0.299);
  border-radius: 10px;
}

/* ::-webkit-scrollbar-button {
  border: none;
}

::-webkit-scrollbar-corner {
}

::-webkit-resizer {
}

::-webkit-scrollbar-track-piece {
}

::-webkit-scrollbar-corner {
} */
