.footer {
  height: 100%; /* Replace 100px with your desired height */
    width: 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: white;
}

