.container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 90%;
    margin: 20px auto;   
    height: auto; 

}

.topTicket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.bottomTicket {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topLeftTicket{
    display:flex;
    flex-direction:row;
    vertical-align:baseline;

}

.name{
    font-weight:bolder;
    margin-left: 20px;
    font-size:30px;
}

.numeroDemande{
    font-size:29px;
    font-weight: 100;
    color:lightgrey;
}

.folderImg{
    width:40px;
    height:40px;
    margin-right:30px;
    display: flex;
    flex-direction: column;
    cursor: pointer;    
    align-items:center;
}

.xlsImg{
    width : 40px;
    height : 40px;
    margin-right:25px;
    display: flex;
    flex-direction: column;
    align-items:center;
    cursor: pointer;
}

.voirMessages {
    width:40px;
    height:40px;
    margin-right:25px;
    display: flex;
    flex-direction: column;
    cursor: pointer;    
    justify-content:center ;
}

.voirMessages img{
    width: 60%;
    height: 60%;
}

.voirMessages img:hover {
    width: 80%;
    height: 85%;
    object-fit: contain;

}

.status{
    width: 170px;
    padding-left: 15px;
    padding-right:15px;
    background-color: rgb(231, 248, 253);
}

.icons{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}