.button {
    overflow: hidden;
    font: inherit;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    line-height: 1.67;
    letter-spacing: .188em;
    text-align: center;
    text-decoration: solid;
    text-transform: uppercase;
    cursor: pointer;
    width: 200px;
    border-width: 0;
    border-radius: 50px;
    border-color: #000080;
    padding: 16px 38px;
    background-color: #000080;
    margin: auto;
    display: block;
    &:hover {
        /*background-color: #1A0E6A;*/
        background-color: #8A2BE2;

    }
}