.divContainer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Aligner les éléments au centre */
    justify-content: center; /* Centrer le contenu verticalement */
    height: 100vh;
    width: 80%;
    text-align: left;
    background: lightcyan;
    position: absolute;
}

strong{
    color:darkblue;
}

h1{

    text-align: left;
    color:darkblue;
}

.popUpOn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:#FFFFFF;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

}

.divDemande {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
}

.insideDemande {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    background-color: #f5f5f5;
}