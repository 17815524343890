.container{
    background-color: lightcyan;
    width: 100%;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    vertical-align:middle;
}

.h1{
    display:flex;
    flex-direction:row;
    justify-content: center;
    margin:auto;
    color: black;
    margin-bottom: 30px;;
    margin-top:25px;
}

.popUp {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

.passwordInput {
    width: 100%;
    max-width: 100%;
    margin-top: 10px; /* Espace au-dessus du champ de mot de passe */
    height:40px;
    border: 1px solid #ccc;
    background-color: rgb(79, 79, 79, 0.2);
    color: black;
    border-radius: 5px;
    opacity: 0.8;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  .selectInput {
    width: 100%;
    max-width: 100%;
    height:40px;
    margin-top: 10px; /* Ajustez cette valeur selon vos besoins */

    margin-bottom: 20px; /* Espace en dessous du select */

    border: 1px solid #ccc;
    background-color: rgb(79, 79, 79, 0.2);
    color: black;
    border-radius: 5px;
    opacity: 0.8;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  .inputDiv {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }


.popUp button:hover {
    background-color: #8A2BE2;
}


.divButton {
    margin-top:50px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
}