.divContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 100%;
    background-color: #f5f5f5;
    overflow-y: hidden;
}

.popUpOn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:#FFFFFF;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

}

.divDemande {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 50vh;
    width: 100%;
    background-color: #f5f5f5;
}

.insideDemande {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    background-color: #f5f5f5;

}

.h1{
    display:flex;
    flex-direction:row;
    justify-content: center;
    margin:auto;
}