.rootFiligrane{
    width:100%;
    max-width: 100%;
    z-index:103;
    top: 0;
    min-height: 100vh;
    background-color: rgba(79, 79, 79, 0.63);


}

.container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    width:max-content;

    height: 100vh;
    box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.6);
    margin-bottom:2px;
    color:darkblue;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#e8fffe;
    width: 30%;
    height: 100%;
    box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.5);    
    hr {
        width: 100%;
        margin: 0;
        color:darkblue;
    }
}

.rightContainerTop {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color:#e8fffe;
    width: 100%;
    color:darkblue;
    margin:auto;

    img{
        color:darkblue;
        height:30px;
        width: 30px;
        object-fit: cover;
        margin-left:40px;
        cursor: pointer;

    }

    h1{
        margin-left:15px;
    }
}

.rightContainerMiddle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color:#e8fffe;
    width: 100%;
    min-height: 40%;
    height: auto;
    margin-bottom:25px;
    
    p {
        margin:auto;
        padding: 0;
        font-size: 14px;
        color: darkblue;
    }
}

.rightContainerBottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color:#e8fffe;
    width: 100%;
    height: 100%;
    color:darkblue;
    margin-top:0px;

    p{
        margin-top:0px;

        margin-left:auto;
        margin-right:auto;
        color:darkblue;
    }

    input {
        width: 80%;
        height: 30px;
        margin-top: 0px;
        border-radius: 5px;
        border: none;
        padding-left: 10px;
        color:darkblue;
    }


}
.time{
    display:flex;
    flex-direction: row;
    color:darkblue;
    margin : auto;
    margin-top:10px;
    vertical-align: middle;

    p{
        color:darkblue;
        display: flex;
        flex-direction: column;
        margin-left:10px;
        vertical-align: middle;
    }

    img{
        vertical-align: middle;

        display:flex;
        flex-direction: column;
        height: 30px;
        width:30px;
        object-fit: cover;
    }
}

/* .statusInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    height: 20%;
    input {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        width: 60%;
        height: 100%;
        border-radius: 30px;
        margin-left: 10px;
        background-color: rgba(39, 28, 169, 0.17);
        border: solid 1px #271CA9;
        color: #271CA9;
        cursor: pointer;
        &:hover {
            background-color: white;
            border: solid 2px #271CA9;
            color: #271CA9;
        }
    }

} */

.leftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*background-color: #D5D5D5;*/
    background-color: #e8fffe;
    width: 70%;
    height: 100%;
    box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.5);
}

.title {
    margin-top: 20px;
    border-radius: 10px;
    background-color: #e8fffe;
    padding: 10px;
    width: 80%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        margin: 0;
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: darkblue;
    }
}

.messageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e8fffe;
    width: 90%;
    height: 77%;
    margin-top: 20px;
    border-radius: 10px;
    hr {
        width: 100%;
        margin: 0;
    }
}

.navMenue {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 40px;
    margin-top: 30px; 
    margin-bottom: 20px;
    vertical-align: top;
    
    
    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 40%;
        height: 100%;
        border-radius: 5px;
        box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1);
        background-color: white;
        border: none;
        cursor: pointer;
        &:hover {
            background-color: #8A2BE2;
            border: 1px solid #D5D5D5;
            color: white;
            & img {
                filter: invert(100%); 
            }
        }
    }
}

.messageDisplay {
    display: flex;
    padding-top:20px;
    padding-bottom:20px;
    margin-right:20px;

    height: 50vh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow: auto;

    border-radius: 10px;

    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 20%;
        height: 10%;
        border-radius: 30px;
        margin-left: 10px;
        background-color: rgba(39, 28, 169, 0.17);
        border: solid 1px #271CA9;
        color: #271CA9;
        cursor: pointer;
        &:hover {
            background-color: white;
            border: solid 2px #271CA9;
            color: #271CA9;
            & img {
                filter: white
            }
        }
    }
}

.filesDisplay{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    vertical-align:middle;
    text-align: left;
    height: 50vh;
    padding-top: 20px;
    overflow: auto;
    padding-bottom:20px;
    margin-right:20px;
    border-radius: 10px;


    font-size:medium;
    color: black;
    img{
        margin-left:20px;
        font-size:medium;
        width:17px;
        height:17px;
        object-fit: cover;
        vertical-align: bottom;
        cursor: pointer;
    }

    ul{
        background-color: white;
        margin-top:20px;
        margin-bottom:20px;
        margin-left:auto;
        margin-right:auto;
        width:80%;
        border-radius:10px;
        padding-top:15px;
        padding-bottom:15px;
        padding-left:35px;
        padding-right:15px;
        box-shadow: -2px 3px 5px 0px rgb(115, 115, 115);

    }

}

.repondreDisplay{
    height: 80vh;
    padding-bottom: 20px;
    overflow-y: auto;    

}


.champ{
    text-align:left;
    display:flex;
    flex-direction: row;
    align-items: center; /* Aligne les éléments verticalement au centre */
    margin-bottom: 5px;
    margin-left:15px;
    margin-top:15px;


    label{
        font-weight: bold;
        color:indigo;
        margin-right:5px;  
      
    }

    p{
        color:darkblue;

    }
}



.containerFromLoadingMail{
    display:flex;
    flex-direction: row-reverse;
}

.tabs { 
    width:90%;
    background-color: #e8fffe;
    height:60vh;
    height: 95%;

}

.tabsOnglet {
    display : flex;
}

.tabsOnglet button {

    padding: 1em;
    width:29%;
    border: none;
    font: inherit;
    border-top-right-radius: 100px;
    border-top-left-radius: 20px;
    vertical-align: top;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    font-size:smaller;
    padding-right:25px;



}

.tabsOnglet button:hover {
    cursor: pointer;
    box-shadow: 0px -8px 9px rgba(87, 86, 86, 0.234);

}

.tab-bodies {
    padding: 20;
    background-color: lightgray;
    flex-grow: 1;
    overflow-y: auto;
    z-index:104;
}

button.active-tab-1 {
    background-color: #cdfcfa; /* Couleur pour Tab 1 */

}

button.active-tab-2 {
    background-color: #cdfcfa; /* Couleur pour Tab 2 */

}

button.active-tab-3 {
    background-color: #cdfcfa; /* Couleur pour Tab 3 */

}