.container {
    /*background-color: rgb(215, 236, 242);*/
    width: 100%;
    overflow: scroll;
    height: 100vh;
    display: flex;
    justify-content: center;
    /*padding-left: 20px;*/
    z-index: 1;

}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wave {
  width: 4px;
  height: 60px;
  background: linear-gradient(45deg, rgb(5, 241, 241), #ca7af8);
  margin: 10px;
  animation: wave 0.5s linear infinite;
  border-radius: 20px;
}

.wave:nth-child(2) {
  animation-delay: 0.1s;
}

.wave:nth-child(3) {
  animation-delay: 0.2s;
}

.wave:nth-child(4) {
  animation-delay: 0.3s;
}

.wave:nth-child(5) {
  animation-delay: 0.4s;
}

.wave:nth-child(6) {
  animation-delay: 0.5s;
}



@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}