.contenantPopup {
    background-color: rgba(79, 79, 79, 0.2);
    z-index: 100;
    width: 100%;
    height: 100%; /* Utilisation de 100% au lieu de 120% pour couvrir toute la hauteur */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Utilisation de "fixed" pour couvrir l'écran */
    top: 0;
    left: 0;
  }
  
  .popUp {
    width: 60%;
    height: 55%;
    margin: auto;
    padding-bottom: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: relative; /* Ajout de "relative" pour le positionnement relatif au contenant */
  }

h1 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-items: center;
}
  
  
  .popUp button:hover {
    background-color: #8a2be2;
  }
  
  .divButton {
    width: 80%;
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .inputDiv {
    width: 40%;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .popUp h1 {
    align-self: center;
    margin-bottom: 20px;
  }
  
  .popUp input {
    width: 80%;
    height: 35%;
    margin-bottom: 10px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(79, 79, 79, 0.2);
    color: black;
  }
  
  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 20px;

    &:hover{
        background-color: lightcyan;
    }
  }
  