.root {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /*height: 100vh; // Ensure the root container takes full viewport height */
    padding-bottom:20px;
    margin-bottom:20px;
}

.pageTitle {
    display: flex;
    flex-direction: row;
    height: 50px;
    background-color: white;
    width: 100%;
    max-width: 100%;
    -webkit-box-shadow:0 0 6px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow:0 0 6px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 6px 1px rgba(121, 121, 121, 0.1);
    z-index: 102;
}

.pageTitle h2 {
    text-align: center;
    color: #000080;
    font-family: helvetica;
}

.body {
    display: flex;
    flex-direction: row;
    /*height: calc(100vh - 50px); /* Subtract pageTitle height to fit the remaining viewport height */
    /*overflow: hidden; /* Prevent the body itself from scrolling */
}

.menuLeft {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px 10px 10px 5px;
    border-right: 1px solid lightgray;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    width: 100%;
    min-height: 100vh;
    background-color: lightcyan;
    overflow: hidden; /* Prevent the container from scrolling */
}

.filterImg {
    height: 46px;
    width:46px;
    object-fit: contain;
    margin-top:10px;
    cursor: pointer;    
}

.retour {
    width: 35px;
    height: 35px;
    object-fit: contain;
    border-radius: 10px;
    margin-left: 2px;
    margin-top: 10px;

    &:hover{
        cursor: pointer;
    }
}

.exportListeIcon{
    height:46px;
    width:46px;
    object-fit: contain;
    padding-top:0;
    cursor: pointer;
}

.demandes {
    flex: 1;
    width: 100%;
    box-sizing: content-box;

}

/*
.demandes table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #000;
    border-radius: 5px;
    color: white;
}

.demandes thead {
    background-color: #000080;
    border: 1px solid #000;
    border-radius: 5px;
}

.demandes th {
    padding: 10px;
    text-align: left;
}

.demandes td {
    padding: 10px;
    text-align: left;
    border: 1px solid #000;
}

.demandes tr {
    border: 1px solid #000;
}

.demandes tbody tr {
    color: black;
}

.demandes tbody tr:nth-child(odd) {
    background-color: rgba(39, 28, 169, 0.5);
}
*/





