.rootFiligrane{
    width:100%;
    top: 0;
    min-height: 100vh;
    background-color: rgb(79, 79, 79, 0.2);
    display: flex;
    flex-direction: row;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /*height: auto;*/
    color:darkblue;
    padding-bottom:20px;
    margin-bottom:20px;

}

.contenuDemande{
   
    display:flex;
    flex-direction: row;
    background-color: lightblue;
    width:100%;
    height: auto;
    margin: 0; /* Assurez-vous que la marge est à 0 */
    padding: 0; /* Assurez-vous que le padding est à 0 */
}

.rightContainer {
    flex: 1;
    box-sizing: content-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    /*background-color: #D5D5D5;*/
    padding-top:20px;
    padding-bottom: 30px;
    background-color: #e8fffe;
    width: 80%;
    height: auto;
    box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
    
}

.messageContainer {
    display: flex;
    box-sizing: content-box;
    flex: 1;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: auto;
    margin-top: 20px;
    border-radius: 10px;
    hr {
        width: 100%;
        margin: 0;
    }
}

.tabs { 
    width:80%;
    background-color: #e8fffe;
    /*min-height:60vh;
    height: auto;*/
    box-sizing: content-box;
    flex: 1;

}

.tab-bodies {
    padding: 20;
    background-color: lightgray;
    flex:1;
    /*flex-grow: 1;
    overflow-y: auto;*/
    z-index:104;
}
















.leftContainer {
    padding-top:20px;
    padding-left:15px;
    padding-right: 15px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#e8fffe;
    position: relative; 

    width: 20%;
    height:auto;
    box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.5); 

    hr {
        width: 100%;
        margin: 0;
        color:darkblue;
    }
}

.demandeTitle {
    display: flex;
    flex-direction: column;
    vertical-align: top;
    justify-content: center; /* Aligne les éléments verticalement au centre */
    align-items: center;
    height:12%;
    width: 100%;
    color:darkblue;
    margin: 0; /* Assurez-vous que la marge est à 0 */
    padding: 0; /* Assurez-vous que le padding est à 0 */
    margin-left:auto;
    margin-right:auto;

    img{
        color:darkblue;
        height:30px;
        width: 30px;
        object-fit: cover;

    }

    h1{
        font-size:xx-large;

        height:30px;
        text-align: center;

        margin: 0px 0 10px 0; /* Ajuste les marges pour centrer */


    }

    h3{
        font-size:medium;

        height:40%;
        margin: 10px 0; /* Ajuste les marges pour centrer */
        text-align: center;
        font-weight: 300;

    }
}



.leftContainerMiddle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color:#e8fffe;
    width: 100%;
    height:auto;
    margin-bottom:25px;
    
    p {
        margin:auto;
        padding: 0;
        font-size: 14px;
        color: darkblue;
    }
}

.time{
    display:flex;
    flex-direction: row;
    color:darkblue;
    margin : auto;
    margin-top:10px;
    vertical-align: middle;

    p{
        color:darkblue;
        display: flex;
        flex-direction: column;
        margin-left:10px;
        vertical-align: middle;
    }

    img{
        vertical-align: middle;

        display:flex;
        flex-direction: column;
        height: 30px;
        width:30px;
        object-fit: cover;
    }
}

/* .statusInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    height: 20%;
    input {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        width: 60%;
        height: 100%;
        border-radius: 30px;
        margin-left: 10px;
        background-color: rgba(39, 28, 169, 0.17);
        border: solid 1px #271CA9;
        color: #271CA9;
        cursor: pointer;
        &:hover {
            background-color: white;
            border: solid 2px #271CA9;
            color: #271CA9;
        }
    }

} */



.title {
    margin-top: 20px;
    border-radius: 10px;
    background-color: #e8fffe;
    padding: 10px;
    width: 80%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        margin: 0;
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: darkblue;
    }
}



.navMenue {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 40px;
    margin-top: 30px; 
    margin-bottom: 20px;
    vertical-align: top;
    
    
    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 40%;
        height: 100%;
        border-radius: 5px;
        box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1);
        background-color: white;
        border: none;
        cursor: pointer;
        &:hover {
            background-color: #8A2BE2;
            border: 1px solid #D5D5D5;
            color: white;
            & img {
                filter: invert(100%); 
            }
        }
    }
}

.messageDisplay {
    display: flex;
    padding-top:20px;
    padding-bottom:20px;
    margin-right:20px;

    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    /*overflow: auto;*/

    border-radius: 10px;

    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 20%;
        height: 10%;
        border-radius: 30px;
        margin-left: 10px;
        background-color: rgba(39, 28, 169, 0.17);
        border: solid 1px #271CA9;
        color: #271CA9;
        cursor: pointer;
        &:hover {
            background-color: white;
            border: solid 2px #271CA9;
            color: #271CA9;
            & img {
                filter: white
            }
        }
    }
}

.filesDisplay{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    vertical-align: center;
    min-height : 50vh;
    height: auto ;
    padding-top: 20px;
    /*overflow: auto;*/
    padding-bottom:20px;
    margin-right:20px;
    border-radius: 10px;


    font-size:medium;
    color: black;
    img{
        margin-left:20px;
        font-size:medium;
        cursor: pointer;

    }

    ul{
        background-color: white;
        margin-top:20px;
        margin-bottom:20px;
        margin-left:auto;
        margin-right:auto;
        width:80%;
        border-radius:10px;
        padding-top:15px;
        padding-bottom:15px;
        padding-left:35px;
        padding-right:15px;
        box-shadow: -2px 3px 5px 0px rgb(115, 115, 115);

    }

}

.repondreDisplay{
    min-height: 50vh;
    padding-bottom: 20px;

}

.champ{
    text-align:left;
    display:flex;
    flex-direction: row;
    align-items: center; /* Aligne les éléments verticalement au centre */
    margin-bottom: 5px;
    margin-left:5px;
    margin-top:5px;
    height:30px;
    font-size:10pt;

    label{
        font-weight: bold;
        color:indigo;
        margin-right:5px;  
      
    }

    p{
        color:darkblue;
        font-size:10pt;
    }
}


/*
.containerFromLoadingMail{
    display:flex;
    flex-direction: row-reverse;
}*/



.tabsOnglet {
    display : flex;
}

.tabsOnglet button {

    padding: 1em;
    width:25%;
    border: none;
    font: inherit;
    border-top-right-radius: 100px;
    border-top-left-radius: 20px;
    vertical-align: top;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    
    margin-right:3px;

    img{
        margin-right:5px;
    }

}

.tabsOnglet button:hover {
    cursor: pointer;
    box-shadow: 0px -8px 9px rgba(87, 86, 86, 0.234);

}






button.active-tab-1 {
    background-color: #cdfcfa; /* Couleur pour Tab 1 */

}

button.active-tab-2 {
    background-color: #cdfcfa; /* Couleur pour Tab 2 */

}

button.active-tab-3 {
    background-color: #cdfcfa; /* Couleur pour Tab 3 */

}