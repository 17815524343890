.container {
    background-color: rgba(79, 79, 79, 0.2);
    z-index: 200;
    width: 100%;
    height: 100%; /* Utilisation de 100% au lieu de 120% pour couvrir toute la hauteur */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Utilisation de "fixed" pour couvrir l'écran */
    top: 0;
    left: 0;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: -3px 3px 6px 4px rgba(0, 0, 0, 0.4);

    p{
        color:black;
    }
}

.closeIconImg {
    min-width: 20px;
    min-height: 20px;
    max-width: 23px;
    max-height: 23px;
    border-radius: 20px;
    object-fit: cover;

    &:hover{
        background-color: lightcyan;
    }
}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border-radius: 20px;

}