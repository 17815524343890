/* LoadingIcon.module.css */
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .center {
    display: flex;
    align-items: center;
  }
  
  .wave {
    width: 4px;
    height: 15px; /* Adjust height for button size */
    background: linear-gradient(45deg, rgb(5, 241, 241), #ca7af8);
    margin: 2px; /* Adjust margin for button size */
    animation: wave 0.5s linear infinite;
    border-radius: 20px;
  }
  
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  
  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  