.TopHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 200;
}

.navigation {
    align-items: center;
    display: flex;
}

.navContainer {
    display: flex;
    align-items: space-between;
    gap: 20px;
}

.navContainer button {
    text-decoration: none;
    color: #271CA9;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover {
        /*background-color: #1A0E6A;*/
        color: #00BFFF;

    }
}


 
