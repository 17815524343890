.buttons{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top:30px;
}

.button{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right:30px;
}

.container {
    background-color: rgba(79, 79, 79, 0.2);
    z-index: 200;
    width: 100%;
    height: 100%; /* Utilisation de 100% au lieu de 120% pour couvrir toute la hauteur */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Utilisation de "fixed" pour couvrir l'écran */
    top: 0;
    left: 0;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: -3px 3px 6px 4px rgba(0, 0, 0, 0.4);

    p{
        color:black;
    }
}


.closeIconImg {
    width: 20px;
    height: 20px;
    border-radius: 20px;

    &:hover{
        background-color: lightcyan;
    }
}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border-radius: 20px;

}