.contenantPopup {
    background-color: rgba(79, 79, 79, 0.2);
    z-index: 90;
    width: 100%;
    height: 100%; /* Utilisation de 100% au lieu de 120% pour couvrir toute la hauteur */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Utilisation de "fixed" pour couvrir l'écran */
    top: 0;
    left: 0;
  }
  
  .popUp {
    width: 60%;
    height: 55%;
    margin: auto;
    padding-bottom: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: relative; /* Ajout de "relative" pour le positionnement relatif au contenant */
  }
  

  
  .divButton {
    width: 80%;
    height: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .popUp h1 {
    align-self: center;
  }
  
  .popUp input {
    height: 30%;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left:5px;
    padding-right:5px;
    margin-left:auto;
    margin-right:auto;

    border: 1px solid #ccc;
    background-color: rgba(79, 79, 79, 0.2);
    color: black;
    border-radius: 5px;
    opacity: 0.8;
  }
  
  .popUp button:hover {
    background-color: #8a2be2;
  }
  
  .h1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    padding-bottom: 0px;
    color: black;
  }

  
  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 20px;

    &:hover{
        background-color: lightcyan;
    }
  }
  