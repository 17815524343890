.container {
    display: flex;
    flex-direction: column;

    border: 1px solid #e0e0e0;
    background-color: #fff;
    width: 100%;
    padding-top:40px;
    overflow-y:auto;
    position: relative; /* Ensure it can scroll */

}

.valider{
    height:100%;
    margin-bottom: 20px;;

}

body, html {
    scroll-behavior: smooth; /* Ensure smooth scroll behavior globally */
}

.titleContainer {
    font-family: Arial, Helvetica, sans-serif;
    color: indigo;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; /* Changement de start à flex-start */
    margin-left:30px;
    align-items: center;
    text-align: left;
    position: sticky; /* Keep the title at the top */

    img {
        display: flex;
        flex-direction: column;
        margin-right:100px;
        margin-left : 0px;
        width: 60px; /* Fixer la largeur de l'image */
        height: auto; /* Laisser la hauteur s'adapter proportionnellement */
        object-fit: contain; /* Maintenir les proportions de l'image */

        &:hover{
            cursor: pointer;
        }
    }
    
    .titlePart {
        margin-bottom: 10px;
        flex-direction: column;
        display:flex;
    }

    .status{
        margin-left:100px;
        vertical-align: middle;
        text-align : right;
        flex-direction: column;
        display:flex;

        p{
            margin-top: 0px;
            font-size: 9pt;
            color:gray;
            text-align: center;

            &:hover{
                cursor: pointer;
                text-decoration: underline;

            }
        }
    }

    .statusFlag{
        
        margin-bottom: 10px;
        margin-right:0px;
        background-color:aqua;
        color:indigo;
        border-radius:5px;
        padding : 10px 15px 10px 15px;
        height:auto;
        text-align: center;
    }
}

.subtitle{
    margin-top:10px;
    display:flex;
    flex-direction: row;

    .left{
        background-color: #9400D3;
        border-radius: 5px;
        color:yellow;
        font-size: 10pt;
        padding : 5px 10px 5px 10px;
        margin-right:20px
    }
}

h2{
    font-family: Arial, Helvetica, sans-serif;
    color:#9400D3;
    margin: 0 auto;
    width:100%;

}

.newStatus {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    color: yellow;
    font-weight: bold;
    border-radius: 5px;
    padding-top: 10px;


}
  
  .newStatus {
    display: flex;
    flex-direction: column;
  }
  
  .newStatus label {
    display: flex;
    flex-direction: column;
    align-items: center; /* Aligner les éléments verticalement */
    margin-bottom: 10px; /* Espacement entre les boutons */
    width:150px;
  }
  
  .newStatus input[type="radio"] {
    margin-right: 5px; /* Espacement entre le bouton radio et le label */
    width:10px;
    height:10px;
  }

  .statusOtherData{
    display:flex;
    flex-direction: row;
    text-align: center;

  }

  .raison{
    padding:10px 10px 10px 10px;
    margin-top:15px;
    color:darkgray;
    width:500px;
    height:100px;
    background-color: white;
    margin:auto;

}

.information {
    padding-top:40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.topInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: darkblue;


    .top {

        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        padding-top:40px;

        div {
            font-weight: bold;
            p {
                color: darkgray;
            }
            input {
                width: 100%;
                height: 30px;
                border-radius: 5px;
                padding: 5px;
                color: white;
                background-color: darkblue;

            }
        }
    }

    .labelsPart{
        display:flex;
        flex-direction: row;
    }

    .statut {
        margin-top:40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        color: yellow;
        font-weight: bold;
        border-radius: 5px;

        h3{
            display: flex;
            flex-direction: column;
            text-align: center;
        }
    }
}









.vendor{
    padding-left : 30px;
    padding-bottom: 20px;
    width: 100%;
    background-color: #EEEEEE;
    margin-left : 80px;
    margin-right: 80px;

}
.vendorCol{
    display: flex;
    flex-direction: row;
}
.vendorRow{
    display: flex;
    flex-direction: row;
    /*width: 100%;*/
    padding-bottom: 30px;
    border-radius: 5px;
    justify-content: space-around;
    
    .div{
        display:flex;
        flex-direction: column;
        margin-left:15px;
        margin-right:15px;
    }
}


.champsVendor {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    input{
        color:gray;
    }
}

.champsVendor p,
.champsVendor input {

    width: 100%; /* Assurez-vous que les éléments occupent toute la largeur du conteneur */
    margin: 0;   /* Supprimez les marges par défaut pour les éléments p */
    text-align: left;

}

.vendorInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: darkblue;
    margin-bottom:20px;
    padding-bottom: 30px;
    padding-top : 40px;

}

.vendorTop {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
    border-radius: 5px;
    color: darkgray;
    padding-bottom: 30px;

    input {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        padding: 5px;
        color: white;
        background-color: darkblue;
    }
}

.vendorInfo1 {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 90%;
    margin-bottom: 30px;
    font-weight: bold;
    border-radius: 5px;
    color: darkgray;
    background-color: white;
    border-radius: 5px;
    h3 {
        color: darkblue;
        margin-left: 10px;
    }
    input {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        padding: 5px;
        color: black;
        background-color: white;
    }
    div {
        margin-left: 10px;
        display: flex;
        justify-content: space-around;
        div {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
    }
}

.vendorInfo2 {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 90%;
    margin-bottom: 30px;
    font-weight: bold;
    border-radius: 5px;
    color: darkblue;
    background-color: white;
    border-radius: 5px;
    padding-bottom: 30px;

    h3 {
        color: darkblue;
        margin-left: 10px;
    }
    input {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        padding: 5px;
        color: black;
        background-color: rgb(214, 214, 214);
    }
    .all {
        margin-left: 10px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    p{
        color:rgb(141, 141, 141);
    }
}

.selectInRegister{
    width: 170px;
}

.selectInFinancialData{
    width: 200px;
}

.selectInAdresses{
    width: 170px;
}

.firstRow {
    display: flex;
    flex-direction: row; /*column-reverse*/
    justify-content: space-around;
    align-items: center;
    text-align: left;
}

.secondRow {
    display: flex;
    flex-direction: row; 
    justify-content: space-around;
    align-items: center;
    text-align: left;
}

.row {
    display: flex;
    flex-direction: row; 
    justify-content: space-around;
    align-items: center;
}

.vendorTableau {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-bottom: 30px;
    background-color: white;
    color: darkblue;
    font-weight: bold;
    border-radius: 5px;
    padding-bottom: 30px;
    padding-top: 10px;
}

.tableau {
    width: 90%;
    margin-bottom: 10px;
    background-color: white;
    color: black;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid black;
}

.financialData {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-bottom: 20px;
    background-color: white;
    color: darkblue;
    font-weight: bold;
    border-radius: 5px;
    padding-bottom: 30px;
    padding-top: 10px;
}

.financialDataDiv {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin-bottom: 30px;
    background-color: white;
    color: #e0e0e0;
    font-weight: bold;
    border-radius: 5px;
    padding-bottom: 30px;
    padding-top: 20px;
}

.section1adresse{
    margin: 20px;
    padding : 30px 30px 50px 30px;

    background-color:lavender;
}

.alertIcon{
    display:flex;
    flex-direction: column;
    height:25px;
    width:25px;
    object-fit: cover;
    margin-left:10px;
}

.serialNumber{
    display: flex;
    flex-direction:row;
    align-items:center;

    p{
        color:gray;
    }
    
}

.tooltip {
    position: fixed;
    padding: 10px 20px;
    border: 1px solid #b3c9ce;
    border-radius: 4px;
    text-align: center;
    font: 14px/1.3 sans-serif;
    color: #333;
    background: #fff;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, .3);
  }

  .modifiable{

    color:darkblue;
    p{
        color:darkblue;
    }
    input{
        background-color:#f0f0f0 ;
    }

    select{
        background-color:#f0f0f0 ;
        height:40px;
    }

  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: -3px 3px 6px 4px rgba(0, 0, 0, 0.4);

    p{
        color:black;
    }
}

.closeIconImg {
    width: 20px;
    height: 20px;
}
.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border-radius: 20px;
    &:hover{
        background-color: lightcyan;
    }
}