.root{
    display:flex;
    flex-direction: column;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
    padding-bottom:20px;
    margin-bottom:20px;

}

.pageTitle{
    display:flex;
    flex-direction: row;
    height:50px;
    background-color: white;
    width:100%;
    max-width:100%;
    -webkit-box-shadow:0 0 6px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow:0 0 6px 3px rgba(0, 0, 0, 0.3);
    box-shadow:0 4px 6px 1px rgba(121, 121, 121, 0.1);
    z-index: 102;
}

.pageTitle h2 {
    text-align: center;
    color: #000080;
    font-family: helvetica;
}

.body{
    background-color: lightcyan;

    display: flex;
    flex-direction: row;
}

.menuLeft{
    display:flex;
    flex-direction: column;
    background-color: white;
    padding:10px 10px 10px 5px;
    border-right: 1px solid lightgray;
}

.container {
    display: flex;
    flex-direction: column;
    background-color: rgb(79, 79, 79, 0.2);
    width: 100%;
    min-height: 100vh;
    background-color: lightcyan;
    /*height:max-content;*/
    overflow: hidden; /* Prevent the container from scrolling */

}

.filterImg {
    height:46px;
    width:46px;
    object-fit: contain;
    padding-top:0;
    cursor: pointer;
}

.logoCarat{
    width:40px;
    height:40px;
    border-radius: 13px;
    margin-left: 2px;
    margin-top:15px;
    cursor: pointer;
}

.exportListeIcon{
    height:46px;
    width:46px;
    object-fit: contain;
    padding-top:0;
    cursor: pointer;
}


.demandes {
    flex: 1;
    width: 100%;
    box-sizing: content-box;
    justify-content: flex-start;
    
    z-index:0;
    position: relative;
    
    /*display: flex;
    flex-direction: column;
    box-sizing: content-box;

    
    margin-left: 10px;
    width: 95%;
    
    box-sizing: content-box;
    height:100%;
    padding-bottom:20px;*/

}


.zindexSup{
    z-index:100;


/*    position: relative;
    flex: 0 0 auto;*/
}

.conversation{
    margin-right: 0;
    right:0;
    z-index:100;
    position : absolute;

}


