.filter {
    width:100%;
    z-index:100;
    position: sticky;
    flex: 0 0 250px; /* ou la largeur désirée pour votre sidebar */
    top: 0;
    height:100%;
    min-height: 100vh;

    background-color: rgb(79, 79, 79, 0.2);

    .encartBlanc{
        background-color: white;
        width: 35%;
        height:100%;
        min-height: 100vh;
        padding-left:15px;
        padding-bottom:20px;
        display: flex;
        text-align: left;
        flex-direction: column;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    }

    input {
        width: 80%;
        height: 26px;
        border-radius: 5px;
        border: none;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom:8px;
        margin-top:2px;
        padding-left:10px;
        background-color: rgb(79, 79, 79, 0.2);


    }
    label{
        margin-left: 10px;
        margin-right: 10px;
        display:flex;
        flex-direction: row;
    }
    select{
        margin-left: 10px;
        margin-right: 5px;
        margin-bottom : 8px;;
        margin-top:5px;
        padding-left:10px;
        height: 30px;
        width:82%;
        background-color: rgb(79, 79, 79, 0.2);

    }
    img {
        width: 15%;
        object-fit: cover;
    }

}

.topFilter {
    padding-left:15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 90%;
    background-color: white;
}


.close{
    height:30px;
    width: 30px;
    cursor: pointer;
    img{
        width : 100%;
        height : auto;
        object-fit: cover;

    }
}

.filterContainer{
    padding-bottom: 20px;
    background-color: white;
}

.dates{
    display:flex;
    flex-direction: row;
    width:89%;

    div{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content:space-between;
    }
}

.bouton{
    margin-top: 10px;
    justify-content: flex-start;
}