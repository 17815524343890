.container {
    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: white;
    /* background-color: rgb(79, 79, 79, 0.2); */
    box-shadow: -2px 3px 5px 0px rgb(115, 115, 115);
    border-radius: 10px;
    margin: 10px auto; /* Combine margin-left and margin-right to auto */
}

.topContainer {
    margin: 25px 0 25px 20px; /* Combine margin-top and margin-bottom */
}

.topContainer img {
    background-color: lightcyan;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    object-fit: cover;
    padding: 10px;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
}

.champ {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align items at the top */
    margin-bottom: 5px;
    font-size: 10pt;
}

.champ label {
    font-weight: bold;
    color: indigo;
    margin-right: 20px; 
}

.champ p {
    margin: 0; /* Remove default margin */
    color: darkblue;
}
