.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height:100%;
    margin-left:auto;
    margin-right:auto;
    padding-top:20px;
    margin-bottom:0px;

}


.topContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-direction: row;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    label{
        text-align:left;
        margin-bottom:7px;
    }

    input{        
        width:100%;
        max-width:100%;
        margin-bottom:15px;
        padding:5px;
    }
}

.textarea {
    background-color: white;
    width:100%;
    max-width:100%;
    height: 80px;
    margin-bottom: 20px;
    padding:5px;
}

input{
    background-color: white;
}

.button {
    margin-top: 20px;
    overflow: hidden;
    font: inherit;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    line-height: 1.67;
    letter-spacing: .188em;
    text-align: center;
    text-decoration: solid;
    text-transform: uppercase;
    cursor: pointer;
    width: 200px;
    border-width: 0;
    border-radius: 50px;
    border-color: #000080;
    padding: 16px 38px;
    background-color: #000080;
    margin: auto;
    display: block;
    &:hover {
        /*background-color: #1A0E6A;*/
        background-color: #8A2BE2;

    }
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.249);

    p{
        color:black;
    }
}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.closeIconImg {
    width: 20px;
    height: 20px;
}




.dragAndDrop {
    position: relative;

    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom:20px;
    align-items: center;
    text-align: left;



    .dropzone {
        position: relative;
        margin-top:10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 70px;
        background-color: white;
        border: 2px dashed white;
        border-radius: 10px;
    
        label {
            font-size: smaller;
        }

        input{
            background-color: white;
        }
    
        &:hover {
            border: 2px dashed cyan;
        }
    }
}


.dropzone input[type="file"] {
    top: 0;
    left: 0;
    width: 100%;
    max-width: fit-content;
    height: 100%;
    padding-right:10px;
    /*opacity: 0;*/
    cursor: pointer;
    margin-bottom: 0px;
    color:white;
}

.filesList {
    list-style-type: none;
    padding-left:10px;

}

.filesList li {
    display: flex;
    align-items: center;
    vertical-align: middle;
    font-size: smaller;
}

.fileName {
    margin-right: 10px;
    color:black;
}

.cancelFile{
        width:12px;
        height:12px;
        object-fit: cover;

        &:hover{
            width:15px;
            height:15px;
            object-fit: cover;        }
        
}